import EnvironmentTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-oiliness/environment/EnvironmentTemplate';

export default {
  title: 'PhotoAnalysisSteps/SkinOiliness/Environment/EnvironmentTemplate'
};

const createStory = componentProps => () => ({
  components: { EnvironmentTemplate },
  componentProps,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <environment-template v-bind='$options.componentProps' />
  </div>`
});

export const Base = createStory({
  excessShine: 'yes',
  onlyGreasyInSummer: 'no'
});
